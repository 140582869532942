import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppMainEssComponent } from './app.main.component';
import { MenuItem } from 'primeng/api';
import { ApiService } from 'src/app/core/service/api.service';
import { MessageBoxService } from 'src/app/core/service/message-box.service';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { ConfigService } from 'src/app/core/service/app.config.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { ActivityMasterService } from '@organization/activity-organization-master/service/activity-master.service';
import { AppConstant } from '@config/app.config';
import { AppService } from 'src/app/app.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import {
    animate,
    group,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { NotificationsService } from 'src/app/util/notifications/service/notifications.service';
import { WebSocketService } from '@core/service/web-socket.service';

@Component({
    styles: [
        `
            :host ::ng-deep {
                .p-tabview .p-tabview-nav li {
                    width: 100%;
                }

                .p-tabview .p-tabview-panels {
                    padding: 4px 0;
                }

                .notif__img-avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f5f6fa;

                    i {
                        color: #0e8ec5;
                        font-size: 20px;
                    }
                }

                .announce__img-avatar {
                    width: 8rem;
                    height: 4rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 4px;
                    }
                }

                .list-item__notification {
                    padding: 0.8rem 0.95rem;
                    cursor: pointer;

                    &:hover {
                        filter: brightness(97%);
                    }

                    &.unread {
                        position: relative;
                        background-color: #e8f2ff;
                        transition: all 0.2s;

                        &:hover {
                            filter: brightness(97%);
                        }
                    }
                }

                .confirm-dialog__flash-banner__mask {
                    z-index: 100002 !important;
                }

                .pop-up__flash-banner__mask {
                    z-index: 100001 !important;
                }

                .announcement__dialog__mask {
                    z-index: 100000 !important;
                }

                .btn__close-announcement-popup {
                    border-radius: 50%;
                    background: transparent !important;
                    color: #9f9f9f !important;
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    width: 2rem !important;
                    height: 2rem !important;
                    padding: 0 !important;

                    &:hover {
                        color: #343a40;
                        border-color: transparent;
                        background-color: #e9ecef !important;
                    }
                }
            }
        `,
    ],
    selector: 'app-topbar-ess',
    animations: [
        trigger('customDialogAnnouncementAnimations', [
            transition(':enter', [
                group([
                    query(
                        '.announcement__dialog',
                        style({ transform: 'translateX(100rem)', opacity: 0 })
                    ),
                    query(
                        '.announcement__dialog',
                        animate(
                            '0.2s ease-in',
                            style({ transform: 'translateX(0)', opacity: 1 })
                        )
                    ),
                ]),
            ]),
            transition(':leave', [
                group([
                    query(
                        '.announcement__dialog',
                        style({ transform: 'translateX(0)', opacity: 1 })
                    ),
                    query(
                        '.announcement__dialog',
                        animate(
                            '0.2s ease-out',
                            style({
                                transform: 'translateX(100rem)',
                                opacity: 0,
                            })
                        )
                    ),
                ]),
            ]),
        ]),
    ],
    templateUrl: './app.topbar.component.html',
})
export class AppTopBarEssComponent implements OnInit, OnDestroy {
    private readonly _web: string | undefined = localStorage.getItem('web');

    private _notifBellPromise: Promise<any> | undefined;
    private _flashBannerPromise: Promise<any> | undefined;
    private _announcementPromise: Promise<any> | undefined;
    private _announceBellPromise: Promise<any> | undefined;
    private _notifTotalUnreadPromise: Promise<any> | undefined;

    public session!: any;
    public companyId!: string | undefined;
    public suggestions!: any[] | undefined;

    public homeLink: string = `/${this._web}`;
    public visibleAnn: boolean = false;
    public onHideFlash: any = {
        show: () => {
            this.isShowConfirm = true;
        },
        accept: () => {
            localStorage.setItem('displayFlashEss', 'false');

            this.visibleFlash = false;
            this.isShowConfirm = false;
        },
        reject: () => {
            this.isShowConfirm = false;
        },
    };
    public announceList: any[] = [];
    public notifListBel: any[] = [];
    public selectedItem: any[] = [];
    public selectedLang: string = localStorage.getItem('locale') || 'en';
    public visibleFlash: boolean = false;
    public isShowConfirm: boolean = false;
    public announceListBel: any[] = [];
    public flashBannerList: any[] = [];
    public notifTotalUnread: string = '0';

    appInfo!:
        | {
              current: string | undefined;
              hasEssAccess: boolean;
              hasMssAccess: boolean;
              hasAdminAccess: boolean;
          }
        | undefined;
    cities = [
        { name: 'PT. Kramayudha Ratu Motor', code: 'NY' },
        { name: 'PT. Lawencon International', code: 'RM' },
    ];
    language = [
        { label: 'EN', value: 'en' },
        { label: 'ID', value: 'id' },
    ];

    constructor(
        public appMain: AppMainEssComponent,
        public api: ApiService,
        public msg: MessageBoxService,
        private auth: AuthenticationService,
        private config: ConfigService,
        public translate: TranslateService,
        private srv: ActivityMasterService,

        private readonly notifSrv: NotificationsService,
        private readonly _router: Router,
        private readonly _appService: AppService,
        private readonly _webSocketService: WebSocketService
    ) {
        this.session = this.auth.getLocalStorage(AppConstant.myEmployee);

        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                appMain.hideNotifications(true);
                appMain.hideTopMenu(true);
            }
        });
    }

    private _connect(): void {
        if (this.auth.isLoggedIn()) {
            const tenantId = this.auth.getLocalStorage(AppConstant.tenantId);

            this._webSocketService.connect(this._web).subscribe((connected) => {
                if (connected) {
                    this._webSocketService
                        .subscribe(`/topic/personal/${this.session.id}`)
                        .subscribe((message) => {
                            const splitText =
                                message.headers.destination.split('/');
                            const uuid = splitText[splitText.length - 1];

                            if (uuid === this.session.id) {
                                this._notifBell();
                                this._notifTotalUnread();
                            }
                        });

                    this._webSocketService
                        .subscribe(`/topic/announcement/tenant/${tenantId}`)
                        .subscribe((message) => {
                            if (message.body) {
                                let resp = JSON.parse(message.body);

                                if (resp.lovTypeAnnouncementCode === 'ANNCH') {
                                    this._announcementShow(true);
                                } else {
                                    this._flashBannerShow(true);
                                }
                                this._announceBell();
                            }
                        });
                    this._webSocketService
                        .subscribe(
                            `/topic/announcement/user/${this.session.id}`
                        )
                        .subscribe((message) => {
                            const splitText =
                                message.headers.destination.split('/');
                            const uuid = splitText[splitText.length - 1];

                            if (uuid === this.session.id) {
                                if (message.body) {
                                    let resp = JSON.parse(message.body);
                                    if (
                                        resp.lovTypeAnnouncementCode === 'ANNCH'
                                    ) {
                                        this._announcementShow(true);
                                    } else {
                                        this._flashBannerShow(true);
                                    }
                                    this._announceBell();
                                }
                            }
                        });
                }
            });
        } else {
            this._webSocketService.disconnect();
        }
    }

    private _notifTotalUnread(): Promise<any> {
        return lastValueFrom(this.notifSrv.getTotalUnread()).then(
            (res: any) => {
                let count: string = '0';
                if (res.data.count >= 99) {
                    count = '99+';
                } else {
                    count = String(res.data.count);
                }

                this.notifTotalUnread = count;
            }
        );
    }

    private _notifBell(): Promise<any> {
        return lastValueFrom(this.notifSrv.getNotifBell()).then((res: any) => {
            this.notifListBel = res.data;
        });
    }

    private _announcementShow(triggerSock: boolean = false): Promise<any> {
        return lastValueFrom(this.notifSrv.getAnnounceShow()).then(
            (res: any) => {
                if (res.data && res.data.length) {
                    this.announceList = res.data;
                    const displayAnnEss = localStorage.getItem('displayAnnEss');

                    if (!displayAnnEss || displayAnnEss === 'true') {
                        localStorage.setItem('displayAnnEss', 'true');
                        this.visibleAnn = true;
                    } else if (triggerSock) {
                        localStorage.setItem('displayAnnEss', 'true');
                        this.visibleAnn = true;
                    }
                }
            }
        );
    }

    private _flashBannerShow(triggerSock: boolean = false): Promise<any> {
        return lastValueFrom(this.notifSrv.getFlashBannerShow()).then(
            (res: any) => {
                if (res.data && res.data.length) {
                    this.flashBannerList = res.data;
                    const displayFlashEss =
                        localStorage.getItem('displayFlashEss');

                    if (!displayFlashEss || displayFlashEss === 'true') {
                        localStorage.setItem('displayFlashEss', 'true');
                        this.visibleFlash = true;
                    } else if (triggerSock) {
                        localStorage.setItem('displayFlashEss', 'true');
                        this.visibleFlash = true;
                    }
                }
            }
        );
    }

    private _announceBell(): Promise<any> {
        return lastValueFrom(this.notifSrv.getAnnounceBell()).then(
            (res: any) => {
                this.announceListBel = res.data;
            }
        );
    }

    loadData() {
        this._notifTotalUnreadPromise = this._notifTotalUnread();
        this._notifBellPromise = this._notifBell();
        this._announceBellPromise = this._announceBell();
        this._announcementPromise = this._announcementShow();
        this._flashBannerPromise = this._flashBannerShow();
    }

    ngOnInit(): void {
        this._connect();
        this.loadData();

        let web: string | undefined = localStorage.getItem('web');

        if (web === 'dashboard-ess') {
            web = 'ess';
        }

        this.appInfo = {
            current: web,
            hasEssAccess: this._appService.hasEssAccess$.value,
            hasMssAccess: this._appService.hasMssAccess$.value,
            hasAdminAccess: this._appService.hasAdminAccess$.value,
        };

        this.defaultLangChecker();
        // this.getDataCompany();
    }

    doLogout() {
        this._webSocketService.disconnect();

        this.api.logout().subscribe({
            next: () => {
                this.auth.destroySession();

                this._router.navigate(['/login']);
            },
            error: () => {
                this.auth.destroySession();

                this._router.navigate(['/login']);
            },
        });
    }

    selectCompany(e) {
        if (this.session.selectedCompanyId != e.id) {
            this.config.setLoading(true);
            this.api
                .post('auth/change-company', { id: e.id }, true)
                .subscribe((res) => {
                    this.auth.createSession(res);
                    this.session = this.auth.getSession();
                    this.config.setLoading(false);
                });
        }
    }

    defaultLangChecker() {
        this.translate.addLangs(['en', 'id']);

        if (localStorage.getItem('locale')) {
            const browserLang = localStorage.getItem('locale');
            this.translate.use(browserLang.match(/en|id/) ? browserLang : 'en');
        } else {
            localStorage.setItem('locale', 'en');
            this.translate.setDefaultLang('en');
        }
    }

    changeLang(language: string) {
        localStorage.setItem('locale', language);
        this.translate.use(language);
    }

    async getDataCompany() {
        await lastValueFrom(this.srv.getCompany())
            .then((res) => {
                this.companyId = res.data[0].name;
                this.auth.setLocalStorage(
                    AppConstant.companyId,
                    res.data[0].id
                );
            })
            .catch((error) => {});
    }

    onChangeWeb(web) {
        localStorage.setItem('web', web);

        this._router.navigate(['/' + web]);
    }
    goToProfile() {
        this._router.navigateByUrl(
            `/${this._web}/profile/person/personal-information`
        );
    }

    onShowNotif() {
        let web = localStorage.getItem('web');
        this.appMain.hideNotifications();

        this._router.navigateByUrl(`/${web}/notifications/list`);
    }

    search(event: AutoCompleteCompleteEvent) {
        this.suggestions = this.auth
            .getLocalStorage(AppConstant.accessEss)
            .filter((el: any) =>
                el.label.toLowerCase().includes(event.query.toLowerCase())
            );
    }

    select(event: any) {
        this._router.navigateByUrl(
            `${this._web}${event.routerLink.toString()}`
        );
    }

    formatDateNotifAnnounce(dateString: string) {
        const inputDate = new Date(dateString);
        const currentDate = new Date();

        const millisecondsDiff = currentDate.getTime() - inputDate.getTime();
        const daysDiff = Math.floor(millisecondsDiff / (1000 * 3600 * 24));

        if (daysDiff === 0) {
            return 'Today';
        } else if (daysDiff >= 1 && daysDiff <= 7) {
            return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
        } else {
            const formattedDate = `${this.getDayNameShort(
                inputDate.getDay()
            )}, ${inputDate.getDate()} ${this.getMonthNameShort(
                inputDate.getMonth()
            )}`;
            return formattedDate;
        }
    }

    redirectToAnnouncement(item) {
        this._router.navigateByUrl(
            `${localStorage.getItem('web')}/notifications/detail/${
                item.id
            }/announcement`
        );
    }

    getDayNameShort(day: number): string {
        const daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return daysShort[day];
    }

    getMonthNameShort(month: number): string {
        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        return months[month];
    }

    handleClickNotif(data) {
        let route = data.route.path;

        if (route) {
            this.checkAndCorrectRoute(route, data);
        } else {
            this.msg.showError('Invalid Route');
        }
    }

    async isRoute404(routePath: string): Promise<boolean> {
        const urlTree: UrlTree = this._router.parseUrl(routePath);
        const is404 = urlTree.root.children.primary === null;
        return is404;
    }

    async checkAndCorrectRoute(routePath: string, data: any) {
        const is404 = await this.isRoute404(routePath);
        const web = routePath.split('/')[0].toLowerCase();

        if (is404) {
            this.msg.showError(
                `Route ${routePath} leads to 404. No navigation performed.`
            );
            return;
        }

        const matchedAccess = Object.entries(this.appInfo).find(
            ([key, hasAccess]) =>
                key.includes('has') &&
                key.replace(/has|Access/g, '').toLowerCase() === web &&
                hasAccess
        );

        await lastValueFrom(this.notifSrv.markAsRead(data.id));
        this._notifBell();
        this._notifTotalUnread();

        if (matchedAccess) {
            this.appMain.hideNotifications();
            window.open(routePath, '_blank');
        } else {
            this.msg.showWarn(
                `No access found for ${web.toUpperCase()}. Navigation to ${routePath} is not allowed.`
            );
        }
    }

    handleClickAnnounce(data) {
        this._router.navigateByUrl(
            `${localStorage.getItem('web')}/notifications/detail/${
                data.id
            }/announcement`
        );
    }

    getImageUrl(item, flash = false): string {
        const foundItem = flash ? item.file.base : item.bannerFile?.file.base;
        if (foundItem) {
            try {
                const imageUrl = this.base64toUrl(foundItem);
                return imageUrl;
            } catch (error) {
                return 'assets/images/no-image-announcement.png';
            }
        } else {
            return 'assets/images/no-image-announcement.png';
        }
    }

    private base64toUrl(base64String: string): string {
        return 'data:image/*;base64,' + base64String;
    }

    getPageAnnouncement(item) {
        return this.announceList.findIndex((obj) => obj.id === item.id) + 1;
    }

    onHideAnnounce() {
        localStorage.setItem('displayAnnEss', 'false');
        this.visibleAnn = false;
    }

    ngOnDestroy(): void {
        this._webSocketService.disconnect();
    }
}
